import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import _ from "lodash";
import SiteNav from "./components/site-nav";
import "./styles/app.css";
import "./styles/slate.css";
import "~/styles/tables.css";
import Alert from "~/components/alert";
import { getCurrentUser } from "~/models/access-user.server";
import { getUserId } from "~/session.server";

export const links: LinksFunction = () => {
  return [
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css?family=Cabin:400,500,700,400italic,500italic,700italic"
    }
  ];
};

export const meta = () => [
  {
    title: "Pete Fowler Construction - Client Access"
  }
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const id = await getUserId(request);
  return {
    user: id ? (await getCurrentUser(request)).currentAccessUser : null
  };
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  if (!(isRouteErrorResponse(error) && [403, 404].includes(error.status))) {
    captureRemixErrorBoundaryError(error);
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <Meta />
        <Links />
      </head>
      <body>
        <div>
          <SiteNav />
          <div className="p-10">
            <Alert mode="danger">
              <div>
                {isRouteErrorResponse(error) ? (
                  error.status === 404 ? (
                    <div>Page not found</div>
                  ) : error.status === 403 ? (
                    <div>
                      <p>You don't have access to this project.</p>
                      <div>
                        If you should have access, please contact us at{" "}
                        <a href="mailto:helpdesk@petefowler.com">
                          helpdesk@petefowler.com
                        </a>{" "}
                        and we'll get it linked to your account.
                      </div>
                    </div>
                  ) : (
                    <div>
                      {_.isString(error.data)
                        ? error.data
                        : JSON.stringify(error.data)}
                    </div>
                  )
                ) : (
                  <div>
                    We're sorry — something's gone wrong. We've been notified
                    and will look into this.
                  </div>
                )}
              </div>
            </Alert>
          </div>
        </div>
      </body>
    </html>
  );
};

export default function App() {
  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div>
          <SiteNav />
          <main className="container">
            <Outlet />
          </main>
        </div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
