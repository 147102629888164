import { useSubmit } from "@remix-run/react";
import NavBar from "~/components/navbar";
import NavLink from "~/components/navlink";
import logo from "~/images/logo-rev.png";
import { useOptionalUser } from "~/utils";

export default function SiteNav() {
  const user = useOptionalUser();
  const submit = useSubmit();

  const logout = () => {
    submit({}, { action: "/logout", method: "post" });
  };

  return (
    <NavBar
      clientAccess
      id="access-navbar-collapse"
      brand={{
        to: "https://www.petefowler.com/",
        label: <img alt="PFCS Client Access" src={logo} />,
        external: true
      }}
      rightMenu={
        <>
          {user && (
            <>
              <NavLink to="/projects" exact={false}>
                Projects
              </NavLink>
              <NavLink to="/publications" exact={false}>
                Publications
              </NavLink>
              <NavLink to="/events" exact={false}>
                Events
              </NavLink>
            </>
          )}
          {user ? (
            <NavLink to={logout}>Log Out</NavLink>
          ) : (
            <>
              <NavLink to="/login">Log In</NavLink>
              <NavLink to="/register">Sign Up</NavLink>
            </>
          )}
        </>
      }
    />
  );
}
